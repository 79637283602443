<template>
  <div>
    <!-- Início filtro -->
    <v-dialog
      v-model="modal.filtro"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="#018656" dark>
          <v-app-bar-nav-icon @click="modal.filtro = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
          <v-icon class="ml-2">
            fas fa-asterisk
          </v-icon>
          <v-toolbar-title class="pl-4">
            Filtrar status de rastreios
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="limparFiltros">
            <v-icon>fas fa-broom</v-icon>
          </v-app-bar-nav-icon>
          <v-app-bar-nav-icon
            @click=" (grid.statusRastreios.paginaAtual = 1), carregarStatusRastreios(), (modal.filtro = false) " >
            <v-icon>fas fa-search</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>
        <v-card-text>
          <v-col class="pt-6">
            <v-form>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="fas fa-fingerprint"
                    label="Código"
                    type="number"
                    autocomplete="off"
                    v-model="filtros.codigo"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    dense
                    outlined
                    prepend-icon="fas fa-shipping-fast"
                    label="Transportadoras"
                    v-model="filtros.cod_transportadora"
                    :items="transportadoras"
                    item-text="transportadora"
                    item-value="codigo"
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <!-- <v-text-field
                    dense
                    outlined
                    prepend-icon="fas fa-tag"
                    label="Descrição"
                    type="text"
                    autocomplete="off"
                    v-model="filtros.descricao"
                    clearable
                  ></v-text-field> -->
                  <v-select
                    dense
                    outlined
                    prepend-icon="fas fa-tag"
                    label="Descrição"
                    v-model="filtros.descricao"
                    :items="descricoes"
                    item-text="descricao"
                    item-value="descricao"
                    clearable
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim filtro -->

    <!-- inicio modal detalhes -->
    <v-dialog
      v-model="dialogEdicao"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="#018656" dark dense fixed>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="dialogEdicao = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>
        <v-card-text style="padding: 12px 0 0 0;">
          <v-card>
            <v-card>
              <div>
                <v-row style="width: 100%;">
                  <v-col cols="11">
                    <v-toolbar-title
                      class="pl-4"
                      style="width: 100%; display: flex; align-items: center"
                    >
                      <v-icon small class="pr-4">fas fa-star</v-icon>
                      Detalhes Status Rastreio
                    </v-toolbar-title>
                  </v-col>
                  <v-col cols="1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-app-bar-nav-icon @click="salvarDetalhes()" v-on="on" >
                          <v-icon color="success">fas fa-download</v-icon>
                        </v-app-bar-nav-icon>
                      </template>
                      <span>Salvar</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </div>
              <v-spacer></v-spacer>
<div>
              <v-card-text>
                <v-divider></v-divider>
                <v-row class="mt-2" style="margin-left: 8px">
                  <div>
                    <v-row>
                      <v-col cols="2">
                        <v-text-field
                          dense
                          outlined
                          label="Código"
                          v-model="detalhes.codigo"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          dense
                          outlined
                          label="Tipo"
                          v-model="detalhes.tipostatus"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="8">
                      </v-col>

                      <!-- </v-row>
                      <v-row> -->

                      <v-col cols="4">
                        <v-text-field
                          dense
                          outlined
                          label="Descrição"
                          v-model="detalhes.descricao"
                          hide-details
                          type="text"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          dense
                          outlined
                          label="Slug"
                          v-model="detalhes.slug"
                          type="text"
                          hide-details
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4">
                        <v-select
                          :items="timeline"
                          label="Timeline"
                          v-model="detalhes.time_line"
                          hide-details
                          outlined
                          style="width: 300px;"
                        ></v-select>
                      </v-col>
                    <!-- </v-row>
                    <v-row> -->
                      <v-col cols="3">
                        <v-select
                          :items="statusObjeto"
                          label="Status Objeto"
                          v-model="detalhes.status_objeto"
                          hide-details
                          outlined
                          style="width: 300px;"
                        ></v-select>
                      </v-col>
                      <v-col cols="3">
                        <v-select
                          :items="codSituacao"
                          v-model="detalhes.cod_situacao"
                          outlined
                          label="Código Situação"
                          hide-details
                          style="width: 300px;"
                        ></v-select>
                      </v-col>
                      <v-col cols="3">
                        <v-select
                          :items="opcoesStatus"
                          v-model="detalhes.devolvido"
                          outlined
                          label="Devolvido"
                          hide-details
                          style="width: 300px;"
                        ></v-select>
                      </v-col>
                      <v-col cols="3">
                        <v-select
                          :items="opcoesStatus"
                          v-model="detalhes.tentativa_entrega"
                          outlined
                          label="Tentativa Entrega"
                          hide-details
                          style="width: 300px;"
                        ></v-select>
                      </v-col>

                      <v-col cols="3">
                        <v-select
                          :items="opcoesStatus"
                          v-model="detalhes.objeto_nao_encontrado"
                          outlined
                          label="Objeto não encontrado"
                          hide-details
                          style="width: 300px;"
                        ></v-select>
                      </v-col>
                      <v-col cols="3">
                        <v-select
                          :items="opcoesStatus"
                          v-model="detalhes.extravio"
                          outlined
                          label="Extravio"
                          hide-details
                          style="width: 300px;"
                        ></v-select>
                      </v-col>
                      <v-col cols="3">
                        <v-select
                          :items="opcoesStatus"
                          v-model="detalhes.roubo"
                          outlined
                          label="Roubo"
                          hide-details
                          style="width: 300px;"
                        ></v-select>
                      </v-col>
                      <v-col cols="3">
                        <v-select
                          :items="opcoesStatus"
                          v-model="detalhes.falha_remetente"
                          outlined
                          label="Falha remetente"
                          hide-details
                          style="width: 300px;"
                        ></v-select>
                      </v-col>
                      <v-col cols="3">
                        <v-select
                          :items="opcoesStatus"
                          v-model="detalhes.falha_fiscal"
                          outlined
                          label="Falha fiscal"
                          hide-details
                          style="width: 300px;"
                        ></v-select>
                      </v-col>
                      <v-col cols="3">
                        <v-select
                          :items="opcoesStatus"
                          outlined
                          label="Aguardando retirada"
                          v-model="detalhes.aguardando_retirada"
                          hide-details
                          style="width: 300px;"
                        ></v-select>
                      </v-col>
                      <v-col cols="3">
                        <v-select
                          :items="opcoesStatus"
                          outlined
                          label="Avaria"
                          v-model="detalhes.avaria"
                          hide-details
                          style="width: 300px;"
                        ></v-select>
                      </v-col>
                      <v-col cols="3">
                        <v-select
                          :items="opcoesStatus"
                          outlined
                          label="Alerta"
                          v-model="detalhes.alerta"
                          hide-details
                          style="width: 300px;"
                        ></v-select>
                      </v-col>

                      <!-- <v-col>
                        <v-card-actions
                          style="justify-content: end; width: 96vw; padding-bottom: 0"
                        >
                          <v-btn
                            class="ma-2"
                            color="success"
                            style="width: 130px"
                            @click="salvarDetalhes()"
                          >
                            Salvar
                          </v-btn>
                        </v-card-actions>
                      </v-col> -->
                    </v-row>
                  </div>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                </v-row>
              </v-card-text>
</div>
            </v-card>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- fim modal detalhes -->

    <!-- Início principal -->
    <v-card>
      <v-toolbar flat>
        <v-icon>
          fas fa-asterisk
        </v-icon>
        <v-toolbar-title class="pl-4">
          Status de Rastreios {{ modal.semclass ? " - Sem classificação" : "" }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom v-if="modal.semclass" >
          <template v-slot:activator="{ on }">
            <v-app-bar-nav-icon @click=" (modal.semclass = !modal.semclass), carregarStatusRastreios(modal.semclass)" v-on="on" >
              <v-icon color="error">fas fa-times</v-icon>
            </v-app-bar-nav-icon>
          </template>
          <span>Fechar Sem Classificação</span>
        </v-tooltip>
        <v-tooltip bottom v-if="semclass > 0" >
          <template v-slot:activator="{ on }">
            <v-app-bar-nav-icon @click=" (modal.semclass = !modal.semclass), carregarStatusRastreios(modal.semclass)" v-on="on" >
              <v-icon color="error">fas fa-medkit</v-icon>
            </v-app-bar-nav-icon>
          </template>
          <span>{{semclass}} Status Sem Classificação</span>
        </v-tooltip>
        <v-app-bar-nav-icon @click="modal.filtro = true">
          <v-icon>fas fa-search</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon @click="fechar">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col class="px-6 py-6">
            <v-data-table
              dense
              :headers="grid.statusRastreios.cabecalho"
              :items="grid.statusRastreios.items"
              :items-per-page="grid.statusRastreios.porPagina"
              hide-default-footer
              class="elevation-1 pt-4"
              :loading="grid.statusRastreios.carregando"
              style="cursor: pointer"
              @click:row="clickRowOpen"
            >
              <template #item.operacoes="{ item }">
                <div class="btnOperacoes">
                  <v-icon
                    size="12"
                    color="green"
                    class="operacoes"
                    @click="abrirModalDetalhes(item)"
                    >fas fa-pen</v-icon
                  >
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col cols="2">
          </v-col>
          <v-col cols="8">
            <v-pagination
              v-model="grid.statusRastreios.paginaAtual"
              :length=" Math.ceil( grid.statusRastreios.totalRegistros / this.grid.statusRastreios.porPagina ) "
              @input="mudarPagina"
              :total-visible="5"
            ></v-pagination>
          </v-col>
          <v-col cols="2">
            <v-select
              dense
              outlined
              label="Resultados por página"
              :items="grid.statusRastreios.qtdsPaginacao"
              item-text="text"
              item-value="value"
              v-model="grid.statusRastreios.porPagina"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
    <!-- Fim principal -->
  </div>
</template>

<script>
import { baseApiUrl, baseApiUrlPersys, showSuccess } from "@/global";
import axios from "axios";

export default {
  name: "Objetos",
  data: () => ({
    dialogEdicao: false,
    tbRec: 1,
    itRec: [
      { tab: "Classificados", content: "Classificados", value: 1 },
      { tab: "Sem Classificação", content: "SemClassificacao", value: 2 },
    ],
    semclass: 0,

    grid: {
      statusRastreios: {
        cabecalho: [
          { text: "#", value: "codigo", sortable: false },
          { text: "Transportadora", value: "transportadora", sortable: false },
          { text: "Tipo", value: "tipostatus", sortable: false },
          { text: "Descrição", value: "descricao", sortable: false },
          { text: "Slug", value: "slug", sortable: false },
          { text: "TimeLine", value: "time_line", sortable: false },
          { text: "Status", value: "status_objeto", sortable: false },
          { text: "Situação", value: "cod_situacao", sortable: false },
          { text: "Devolvido", value: "devolvido", sortable: false },
          { text: "Tentativa Entrega", value: "tentativa_entrega", sortable: false },
          { text: "Não Encontrado", value: "objeto_nao_encontrado", sortable: false },
          { text: "Extravio", value: "extravio", sortable: false },
          { text: "Roubado", value: "roubo", sortable: false },
          { text: "Falha Remetente", value: "falha_remetente", sortable: false },
          { text: "Falha Fiscal", value: "falha_fiscal", sortable: false },
          { text: "Aguardando Retirada", value: "aguardando_retirada", sortable: false },
          { text: "Avaria", value: "avaria", sortable: false },
          { text: "Alerta", value: "alerta", sortable: false },
          { text: "", value: "operacoes", sortable: false },
        ],
        items: [],
        carregando: false,
        paginaAtual: 1,
        totalRegistros: 0,
        qtdsPaginacao: [10, 15, 50, 100],
        porPagina: 50,
      },
    },
    modal: {
      filtro: false,
      dialogEdicao: false,
      semclass: false,
    },
    filtros: {
      codigo: "",
      cod_transportadora: "",
      descricao: "",
    },
    transportadoras: [],
    descricoes: [],
    detalhes: {},
    timeline: [{text: '0-Aguardando Postagem', value: "0"},{text: '1-Postado', value: "1"},{text: '2-Transporte', value: "2"},{text: '3-Saiu Entrega', value: "3"},{text: '4-Entregue', value: "4"}],
    statusObjeto: [{text: 'I - Importado', value: "I"},{text: 'A - Aberto', value: "A"},{text: 'D - Entregue', value: "D"},{text: 'E - Erro', value: "E"},{text: 'F - Finalizado', value: "F"}],
    codSituacao: [{text: '0 - Erro/Pausado', value: 0},{text: '1 - Data Prevista', value: 1},{text: '2 - Aberto/Rastreio', value: 2},{text: '3 - Entregue/Finalizado', value: 3}],
    opcoesStatus: [{text: "0 - Sem Ocorrência", value: "0"},{text: "1 - Ocorrência", value: "1"}]
  }),
  mounted() {
    this.carregarStatusRastreios();
    this.carregarTransportadoras();
    this.carregarStatusCatego();
  },
  beforeMount() {
    this.tabItems(0);
    this.tabItRec(0);
    this.getFieldText(this.itRec[0].tab);
  },
  methods: {
    tabItems(tab) {
      this.tbRec = tab.value;
    },
    tabItRec(tbRec) {
      this.tbRec = tbRec.value;
    },
    getFieldText(nameTab) {
      this.titleTab = nameTab;
    },
    clickRowOpen(value) {
      this.abrirModalDetalhes(value);
    },
    abrirModalDetalhes(item) {
      this.dialogEdicao = true;
      this.detalhes = item;
      console.log(item)
    },
    async salvarDetalhes() {
      console.log(this.detalhes);
      await axios
        .put(`${baseApiUrlPersys}/status_rastreio/${this.detalhes.codigo}`, {
          aguardandoRetirada: this.detalhes.aguardando_retirada,
          codSituacao: this.detalhes.cod_situacao,
          codTransportadora: this.detalhes.cod_transportadora,
          descricao: this.detalhes.descricao,
          devolvido: this.detalhes.devolvido,
          extravio: this.detalhes.extravio,
          falhaFiscal: this.detalhes.falha_fiscal,
          falhaRemetente: this.detalhes.falha_remetente,
          objetoNaoEncontrado: this.detalhes.objeto_nao_encontrado,
          roubo: this.detalhes.roubo,
          slug: this.detalhes.slug,
          statusObjeto: this.detalhes.status_objeto,
          time_line: this.detalhes.time_line,
          tentativaEntrega: this.detalhes.tentativa_entrega,
          avaria: this.detalhes.avaria,
          alerta: this.detalhes.alerta,
        })
        .then((res) => {
          if(res.status == 200) {
            showSuccess("Status editado com sucesso!");
          } else {
            showError("Status não editado!");
          }
        })
        .finally(()=>{
          this.dialogEdicao = false;
        })
    },
    limparFiltros() {
      this.filtros.codigo = "";
      this.filtros.cod_transportadora = "";
      this.filtros.descricao = "";
    },
    fechar() {
      this.$router.push("/");
    },
    carregarStatusRastreios( filtrar ) {
      this.grid.statusRastreios.carregando = true;
      let url = `${baseApiUrl}/status-rastreios?page=${this.grid.statusRastreios.paginaAtual}`;
      if (this.filtros.codigo != "")
        url += `&codigo=${this.filtros.codigo}`;
      if (this.filtros.cod_transportadora != "")
        url += `&cod_transportadora=${this.filtros.cod_transportadora}`;
      if (this.filtros.descricao != "")
        url += `&descricao=${this.filtros.descricao}`;
      if (Number(this.grid.statusRastreios.porPagina) > 0)
        url += `&por_pagina=${this.grid.statusRastreios.porPagina}`;
      if (filtrar == true)
        url += `&semclass=sim`;

      axios
        .get(url)
        .then((res) => {
          this.grid.statusRastreios.items = res.data.dados.data;
          this.grid.statusRastreios.totalRegistros = res.data.dados.total;
          this.grid.statusRastreios.porPagina = Number(res.data.dados.per_page);

          this.semclass = res.data.semclass;
        })
        .finally(() => (this.grid.statusRastreios.carregando = false));
    },
    mudarPagina(pagina) {
      this.grid.statusRastreios.paginaAtual = pagina;
      this.carregarStatusRastreios();
    },
    carregarTransportadoras() {
      let url = `${baseApiUrl}/transportadoras`;
      axios.get(url).then((res) => {
        this.transportadoras = res.data.transportadoras;
      });
    },
    carregarStatusCatego() {
      let url = `${baseApiUrl}/catego-rastreios`;
      axios.get(url).then((res) => {
        this.descricoes = res.data.dados;
      });
    },
  },
  watch: {
    "grid.statusRastreios.porPagina": {
      handler: function (val, oldVal) {
        if (val != oldVal) {
          if (val > 0) {
            this.grid.statusRastreios.porPagina = Number(val);
            this.mudarPagina(1);
          } else {
            this.grid.statusRastreios.porPagina = 50;
            this.mudarPagina(1);
          }
        }
      },
    },
  },
};
</script>
<style scoped>
.operacoes {
  padding-right: 14px;
  cursor: pointer;
  justify-content: flex-end;
}
.operacoes::after {
  background-color: transparent;
}
.active {
  background-color: #018656;
  color: #fff;
}
</style>
